var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{directives:[{name:"loading",rawName:"v-loading",value:(_vm.isLoading),expression:"isLoading"}],staticClass:"p-4"},[_c('CircleInfo',{attrs:{"isAdmin":_vm.isAdmin,"isMember":_vm.isMember,"isOwner":_vm.isOwner,"circleData":_vm.circleData},on:{"onRefresh":_vm.handleGetCircleDetail}}),_c('CircleFeatureList',{attrs:{"circleData":_vm.circleData,"isAdmin":_vm.isAdmin,"isMember":_vm.isMember,"isOwner":_vm.isOwner,"unread_message_count":_vm.unread_message_count,"tabsTotalNumber":_vm.tabsTotalNumber},on:{"onRefresh":_vm.handleGetCircleDetail}}),_c('RequestJoinChatV2',{attrs:{"members":_vm.requestMembers,"isOwner":_vm.isOwner,"circleData":_vm.circleData},on:{"onGetJoinMembers":_vm.getMembersJoinChat,"onGetMembers":(status, isNext, callback) => {
        _vm.getCircleMembers(status, isNext, callback);
        _vm.getTabsTotalNumber();
      },"onRefresh":(callback) => {
        _vm.handleGetCircleDetail(callback);
        _vm.getMembersJoinChat();
      }}}),(_vm.circle_member && _vm.circle_member.id && _vm.circleData.id)?_c('CircleMembersV2',{attrs:{"circleData":_vm.circleData,"circleMember":_vm.circle_member,"isAdmin":_vm.isAdmin,"isMember":_vm.isMember,"isOwner":_vm.isOwner,"tabsTotalNumber":_vm.tabsTotalNumber,"members":_vm.members,"memberPagination":_vm.memberPagination},on:{"onGetMembers":(status, isNext, callback) => {
        _vm.getCircleMembers(status, isNext, callback);
        _vm.getTabsTotalNumber();
      },"onRefresh":(callback) => {
        _vm.handleGetCircleDetail(callback);
        _vm.getMembersJoinChat();
      }}}):_vm._e()],1)
}
var staticRenderFns = []

export { render, staticRenderFns }