<template>
  <div v-loading="isLoading" class="p-4">
    <CircleInfo
      :isAdmin="isAdmin"
      :isMember="isMember"
      :isOwner="isOwner"
      :circleData="circleData"
      @onRefresh="handleGetCircleDetail"
    />
    <CircleFeatureList
      :circleData="circleData"
      :isAdmin="isAdmin"
      :isMember="isMember"
      :isOwner="isOwner"
      :unread_message_count="unread_message_count"
      :tabsTotalNumber="tabsTotalNumber"
      @onRefresh="handleGetCircleDetail"
    />
    <RequestJoinChatV2
      :members="requestMembers"
      :isOwner="isOwner"
      :circleData="circleData"
      @onGetJoinMembers="getMembersJoinChat"
      @onGetMembers="
        (status, isNext, callback) => {
          getCircleMembers(status, isNext, callback);
          getTabsTotalNumber();
        }
      "
      @onRefresh="
        (callback) => {
          handleGetCircleDetail(callback);
          getMembersJoinChat();
        }
      "
    />
    <CircleMembersV2
      v-if="circle_member && circle_member.id && circleData.id"
      :circleData="circleData"
      :circleMember="circle_member"
      :isAdmin="isAdmin"
      :isMember="isMember"
      :isOwner="isOwner"
      :tabsTotalNumber="tabsTotalNumber"
      :members="members"
      :memberPagination="memberPagination"
      @onGetMembers="
        (status, isNext, callback) => {
          getCircleMembers(status, isNext, callback);
          getTabsTotalNumber();
        }
      "
      @onRefresh="
        (callback) => {
          handleGetCircleDetail(callback);
          getMembersJoinChat();
        }
      "
    />
  </div>
</template>

<script>
import ImageBox from '@/components/Common/Image.vue'
import ModalEditCircle from './ModalEditCircle.vue'
import defaultImage from '@/assets/images/no-avt.png'
import CircleFeatureList from './CircleFeatureList.vue'
import CircleMembersV2 from './CircleMembersV2.vue'
import CircleInfo from './CircleInfo.vue'
import RequestJoinChatV2 from './RequestJoinChatV2.vue'
import e3kit from '@/libs/e3kit'

export default {
  name: 'CircleDetailV2',
  components: {
    ImageBox,
    ModalEditCircle,
    CircleFeatureList,
    CircleMembersV2,
    CircleInfo,
    RequestJoinChatV2
  },
  data () {
    return {
      isLoading: true,
      circleData: {},
      defaultImage,
      circle_member: {},
      unread_message_count: 0,
      activeConversation: null,
      tabsTotalNumber: {
        specialist_count: 0,
        patient_count: 0,
        invited_count: 0,
        pending_count: 0,
        admin_count: 0,
        member_count: 0
      },
      members: [],
      memberPagination: {},
      requestMembers: []
    }
  },
  watch: {
    $route: {
      deep: true,
      async handler () {
        await this.handleGetCircleDetail()
        this.getCircleMembers()
      }
    }
  },
  computed: {
    isMember () {
      return !!this.circle_member && this.circle_member.status === 2
    },
    isAdmin () {
      return (
        (!!this.isMember && this.circle_member.role === 1) ||
        this.circleData?.created_by === this.$user?.id
      )
    },
    isOwner () {
      return this.circleData?.created_by === this.$user?.id
    }
  },
  mounted () {
    this.handlePrepare()
  },
  methods: {
    async handleGetCircleDetail (callback) {
      if (!this.$route.params.id) return

      try {
        const response = await this.$rf
          .getRequest('DoctorRequest')
          .handleGetCircleDetailV2(this.$route.params.id)
        if (response.status === 200) {
          this.circleData = response.data || {}
          callback && callback(response.data)
          return response.data
        }
      } catch (error) {
        console.log(error)
        this.$toast.open({
          message: this.$t('circle_feature.lbl_permission_msg'),
          type: 'warning'
        })
      }
    },
    handleOpenEditModal () {
      this.$refs.ModalEditCircle.handleOpen(this.circleData)
    },
    async getCurrentMember () {
      if (!this.$user.id || !this.$route.params.id) return

      const response = await this.$rf
        .getRequest('DoctorRequest')
        .getMemberByUserId(this.$route.params.id, this.$user.id, null, false)
        .then(async (r) => {
          this.circle_member = {
            ...r.data
          }

          return r
        })
        .catch((error) => {
          console.error(error)
        })

      return response
    },
    async handlePrepare () {
      try {
        this.isLoading = true

        const circleResponse = await this.handleGetCircleDetail()
        const circleMemberResponse = await this.getCurrentMember()
        await this.getCircleMembers()

        if (this.isOwner) {
          await this.getMembersJoinChat()
        }

        this.getTabsTotalNumber()
        if (
          ((circleMemberResponse && circleMemberResponse.status === 2) ||
            circleResponse?.created_by === this.$user?.id) &&
          this.$conversationsClient &&
          circleMemberResponse?.twilio_conversation?.twilio_conv_id
        ) {
          this.createConversation(
            circleMemberResponse?.twilio_conversation?.twilio_conv_id
          )
        }
      } catch (error) {
        console.log(error)
      } finally {
        this.isLoading = false
      }
    },
    async createConversation (id) {
      this.activeConversation =
        await this.$conversationsClient.getConversationBySid(id)
      this.activeConversation.on('messageAdded', this.getUnreadChat)
      this.getUnreadChat()
    },
    async getUnreadChat () {
      this.unread_message_count =
        (await this.activeConversation.getUnreadMessagesCount()) || 0
    },
    async getTabsTotalNumber () {
      if (!this.circleData?.id) return

      await this.$rf
        .getRequest('DoctorRequest')
        .getTabsTotalNumber(this.circleData.id)
        .then((r) => {
          this.tabsTotalNumber = { ...r.data }
        })
        .catch(() => {
          this.tabsTotalNumber = { ...this.tabsTotalNumber }
        })
    },

    async getCircleMembers (status, isNext, callback, isRequestChat) {
      let self = this
      if (!this.$route.params.id) return
      let page = 1
      if (!isNext) {
        self.members = []
      } else {
        page = this.memberPagination?.page_num + 1
      }
      let params = {
        page_num: page,
        page_size: 10,
        circle_id: this.$route.params.id
      }
      if (!isNaN(status)) {
        if (status === 3) {
          params.status = 2
          params.role = 1
        } else if (status === 2) {
          params.status = 2
          // params.role = 2
        } else {
          params.status = status
        }
      } else {
        params.status = 2
      }

      if (isRequestChat) {
        params.request_chat = 1
      }

      await this.$rf
        .getRequest('DoctorRequest')
        .getLiveCircleMembers(params)
        .then(async (r) => {
          const responseData = r.data || {}

          if (isRequestChat) {
            this.requestMembers = responseData.data || []

            return
          }

          let membersData
          if (isNext) {
            membersData = [...this.members, ...(responseData?.data || [])]
          } else {
            membersData = responseData?.data || []
          }

          if (this.circleData?.migrate_channel_gs?.cid) {
            const groupId = `group_${this.circleData?.migrate_channel_gs?.cid}`

            const group = await e3kit.getGroupCardData(
              groupId,
              this.circleData?.created_by?.toString()
            )
            const participants = group?.participants || []

            this.members = await Promise.all(
              membersData?.map(async (item) => {
                return {
                  ...item,
                  is_not_exist_groupVG:
                    participants.length &&
                    !participants?.some(
                      (participantItem) =>
                        participantItem === String(item?.user_id)
                    )
                }
              })
            )
            this.memberPagination = responseData?.page || {}
          } else {
            this.members = membersData
            this.memberPagination = responseData?.page || {}
          }
        })
        .catch((e) => {
          console.log(e)
          self.$toast.open({
            message: this.$t('circle_feature.lbl_permission_msg'),
            type: 'warning'
          })
        })
        .finally(() => {
          callback && callback()
        })
    },
    async getMembersJoinChat () {
      if (!this.$route.params.id) return

      let params = {
        page_num: 1,
        page_size: 10000,
        circle_id: this.$route.params.id,
        status: 2,
        request_chat: 1
      }

      await this.$rf
        .getRequest('DoctorRequest')
        .getLiveCircleMembers(params)
        .then(async (r) => {
          const responseData = r.data || {}

          this.requestMembers = responseData.data || []
        })
        .catch((e) => {
          console.log(e)
        })
    }
  },
  destroyed () {
    if (this.activeConversation) {
      this.activeConversation.removeListener(
        'messageAdded',
        this.getUnreadChat
      )
    }
  }
}
</script>

<style lang="scss" scoped>
.cs-box-shadow {
  box-shadow: 1px 6px 200px -68px rgba(0, 0, 0, 0.52);
}
</style>
