<template>
  <div>
    <el-dialog
      :show-close="true"
      :visible.sync="dialogVisible"
      :before-close="handleClose"
      width="60%"
      append-to-body
      custom-class="cs-modal"
    >
      <template slot="title">
        <div>
          <h2
            class="text-xl font-bold text-start m-0 text-black"
          >{{ $t('lbl_edit_circle_information') }}</h2>
          <el-divider class="mt-2 mb-0" />
        </div>
      </template>
      <div v-loading="isLoading" class="flex flex-col gap-3 text-black">
        <div>
          <div class="fs-16">{{ $t('lbl_circle_name') }}</div>
          <el-input :placeholder="$t('lbl_enter_circle_name')" v-model="form.name"></el-input>
        </div>
        <div>
          <div class="fs-16">{{ $t('lbl_description') }}</div>
          <el-input
            type="textarea"
            :autosize="{ minRows: 5}"
            :placeholder="$t('lbl_enter_description')"
            v-model="form.description"
          ></el-input>
        </div>
        <div class="flex items-center">
          <div class="icinfo mr-3" v-if="form.logo">
            <img class="w-24 h-24 object-contain" :src="form.logo" :alt="form.name" />
          </div>
          <div class="w-full">
            <div class="mb-1 font-medium text-black">{{ $t('lbl_upload_logo_circle') }}</div>
            <div class="cs-input-file" @click="onShowPickLogo">
              <div class="cs-select-file">{{$t('lbl_select_file')}}</div>
              <div
                class="flex items-center justify-center flex-1"
              >{{fileName.logo || $t('lbl_no_file_selected')}}</div>
            </div>
            <input
              v-show="false"
              id="circle-file-logo"
              class="form-control bg-white border mb-0"
              name="circle-file-logo"
              type="file"
              accept="image/*"
              @change="(e) => onSelectFile(e, 'logo')"
            />
          </div>
        </div>
        <div class="flex items-center">
          <div class="icinfo mr-3" v-if="form.background">
            <img class="w-24 h-24 object-contain" :src="form.background" :alt="form.name" />
          </div>
          <div class="w-full">
            <div class="mb-1 font-medium text-black">{{ $t('lbl_upload_background_photo') }}</div>
            <div @click="onShowPickBackground" class="cs-input-file">
              <div class="cs-select-file">{{$t('lbl_select_file')}}</div>
              <div
                class="flex items-center justify-center flex-1"
              >{{fileName.background || $t('lbl_no_file_selected')}}</div>
            </div>
            <input
              v-show="false"
              id="circle-file-background"
              class="form-control bg-white border mb-0"
              name="circle-file-background"
              type="file"
              accept="image/*"
              @change="(e) => onSelectFile(e, 'background')"
            />
          </div>
        </div>

        <div class="flex justify-between">
          <button
            type="button"
            class="btn bg-red-400 hover:bg-red-400 text-white fw-600"
            v-if="circleData && $user && circleData.created_by === $user.id"
            @click="deleteCircle"
          >{{$t('circle_feature.lbl_del_circle')}}</button>

          <div>
            <button
              :disabled="isLoading"
              class="btn btn-FEF3F2"
              @click="handleClose"
              type="button"
            >{{ $t("multidisciplinary_board.btn_cancel") }}</button>
            <button
              :disabled="isLoading"
              class="btn bg-pri bd-pri text-white ml-3"
              @click="handleUpdateCircle"
              type="button"
            >{{ $t("multidisciplinary_board.lbl_save") }}</button>
          </div>
        </div>
      </div>
    </el-dialog>
  </div>
</template>

<script>
const DEFAULT_FORM = {
  name: '',
  description: '',
  logo: null,
  background: null
}

export default {
  name: 'ModalEditCircle',

  data () {
    return {
      isLoading: false,
      dialogVisible: false,
      form: {
        ...DEFAULT_FORM
      },
      fileName: {
        logo: '',
        background: ''
      },
      circleData: {}
    }
  },
  methods: {
    handleClose () {
      this.dialogVisible = false
      this.form = {
        ...DEFAULT_FORM
      }
    },
    handleOpen (data) {
      this.dialogVisible = true

      this.circleData = data || {}
      this.form = {
        name: data?.name,
        description: data?.description,
        logo: data?.logo,
        background: data?.background
      }
    },
    onShowPickLogo () {
      window.$('input[id="circle-file-logo"]').trigger('click')
    },
    onShowPickBackground () {
      window.$('input[id="circle-file-background"]').trigger('click')
    },
    async handleUpdateCircle () {
      if (!this.circleData?.id) return

      if (!this.form.name) {
        alert(this.$t('circle_feature.lbl_please_enter_circle_name'))
        return
      }
      let params = {
        ...this.form,
        name: this.form.name,
        description: this.form.description,
        is_private: this.circleData.is_private
      }
      this.isLoading = true
      await this.$rf
        .getRequest('DoctorRequest')
        .updateCircle(this.circleData.id, params)
        .then(() => {
          this.isLoading = false
          this.$toast.open({
            message: this.$t('lbl_update_success'),
            type: 'success'
          })
          this.dialogVisible = false
          this.$emit('onRefresh')
        })
        .catch(error => {
          console.error(error)
          this.$toast.open({
            message: this.$t('lbl_udpate_fail'),
            type: 'success'
          })
        })
        .finally(() => {
          this.isLoading = false
        })
    },
    async onSelectFile (event, targetKey) {
      const res = await this.uploadFiles(
        event.target.files[0],
        this.circleData
      )
      this.form[targetKey] = res
      this.fileName[targetKey] = event.target.files[0]?.name
    },
    async uploadFiles (file, circle) {
      const params = {
        file,
        FolderTarget: `circle`,
        IdTarget: circle.id
      }
      const formData = new FormData()

      for (const key in params) {
        formData.append(key, params[key])
      }

      const res = await this.$rf
        .getRequest('DoctorRequest')
        .uploadFileTenant(formData)
      return res.data.fileUrl
    },
    async deleteCircle () {
      let self = this
      if (!this.circleData?.id) return

      let r = prompt(this.$t('circle_feature.lbl_del_confirm'))
      if (!r || r.toLowerCase() !== 'yes') return

      await this.$rf
        .getRequest('DoctorRequest')
        .deleteCircle(this.circleData.id)
        .then(result => {
          self.$toast.open({
            message: this.$t('circle_feature.lbl_deleted_msg'),
            type: 'success'
          })
          self.$router.push({ name: 'CircleList' })
        })
        .catch(err => {
          console.log(err)
        })
        .finally(() => {
          self.loading = false
        })
    }
  }
}
</script>

<style lang="scss" scoped>
::v-deep {
  .cs-modal {
    .el-dialog__header {
      padding-bottom: 0;
    }

    .el-dialog__body {
      padding: 20px;
    }
  }

  .el-input__inner {
    background: white !important;
    border: 1px solid #dee2e6 !important;
    border-radius: 4px !important;
    width: 100%;
  }
}

.cs-width {
  min-width: calc(100vh - 500px);
  width: 100%;
  overflow-x: scroll;
}

.cs-input-file {
  display: flex;
  align-items: center;
  width: 100%;
  height: 46px;
  border: 1px solid rgba(101, 109, 119, 0.16) !important;
  border-radius: 4px;
  cursor: pointer;
}

.cs-select-file {
  display: flex;
  align-items: center;
  justify-content: center;
  border-right: 1px solid rgba(101, 109, 119, 0.16) !important;
  background-color: #f6f8fb;
  width: 20%;
  height: 100%;
  padding: 0 8px;
}
</style>