<template>
  <div>
    <div class="flex flex-wrap justify-start gap-3 mt-4 text-black w-full">
      <div class="cs-item" style="cursor: default">
        <div class="font-bold fs-34 text-blue-900">
          {{ tabsTotalNumber.member_count || 0 }}
        </div>
        <div>{{ $t("lbl_members") }}</div>
      </div>
      <div class="cs-item relative" v-if="isMember || isAdmin" @click="goToChat($event, circleData)" :class="[
        unread_message_count ? 'has-unread-chat' : '',
        hasChatChannelV2 || (!hasJoinedChatChannelV2 && !isOwner)
          ? ''
          : 'disabled',
        hasJoinedChatChannelV2 || isOwner ? '' : 'cursor-default',
      ]">
        <div :class="hasJoinedChatChannelV2 || isOwner ? '' : 'opacity-40'">
          <svg width="42" height="42" viewBox="0 0 48 48" fill="none" xmlns="http://www.w3.org/2000/svg" class="mr-2">
            <path d="M30 22V8H8V24.34L10.34 22H12H30Z" fill="#20419B" />
            <path
              d="M32 26C33.1 26 34 25.1 34 24V6C34 4.9 33.1 4 32 4H6C4.9 4 4 4.9 4 6V34L12 26H32ZM8 24.34V8H30V22H10.34L8 24.34ZM44 14C44 12.9 43.1 12 42 12H38V30H12V34C12 35.1 12.9 36 14 36H36L44 44V14Z"
              fill="#20419B" />
          </svg>
          <p class="mb-0 txt-pri fs-20 fw-700" v-if="unread_message_count">
            +{{ unread_message_count }} {{ $t("circle_feature.lbl_new") }}
          </p>
          <!-- <div class="red-dot-new" v-if="unread_message_count">
          <svg
            width="17"
            height="17"
            viewBox="0 0 17 17"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
          >
            <circle cx="8.5" cy="8.5" r="8.5" fill="#D92D20" />
          </svg>
          </div>-->

          <div class>{{ $t("Chat") }}</div>
        </div>
        <div v-if="!hasJoinedChatChannelV2 && !isOwner" @click="handleRequestJoinChatChannel"
          class="request-chat relative z-10" :class="hasSendRequestChatV2 && 'send-request'">
          {{ $t(hasSendRequestChatV2 ? "Đã gửi yêu cầu" : "Yêu cầu tham gia") }}
        </div>
      </div>
      <div class="cs-item" @click="goToAttachment" v-if="isMember || isAdmin">
        <svg width="42" height="42" viewBox="0 0 48 48" fill="none" xmlns="http://www.w3.org/2000/svg" class="mr-2">
          <path d="M8 16H40V36H8V16Z" fill="#20419B" />
          <path
            d="M40 12H24L20 8H8C5.8 8 4.02 9.8 4.02 12L4 36C4 38.2 5.8 40 8 40H40C42.2 40 44 38.2 44 36V16C44 13.8 42.2 12 40 12ZM40 36H8V16H40V36Z"
            fill="#20419B" />
        </svg>
        <div>{{ $t("home_page.lbl_org_resource") }}</div>
      </div>
      <div class="cs-item" @click="goToMeet" v-if="isMember || isAdmin">
        <svg class="mx-2" width="36" height="36" viewBox="0 0 36 28" fill="none" xmlns="http://www.w3.org/2000/svg">
          <path
            d="M34.0683 4.672C33.3483 4.32 31.838 3.952 29.7834 5.264L27.202 6.928C27.0088 1.952 24.638 0 18.878 0H8.34146C2.33561 0 0 2.128 0 7.6V20.4C0 24.08 2.19512 28 8.34146 28H18.878C24.638 28 27.0088 26.048 27.202 21.072L29.7834 22.736C30.8722 23.44 31.8205 23.664 32.5756 23.664C33.2254 23.664 33.7346 23.488 34.0683 23.328C34.7883 22.992 36 22.08 36 19.792V8.208C36 5.92 34.7883 5.008 34.0683 4.672ZM16.2439 13.008C14.4351 13.008 12.9424 11.664 12.9424 10C12.9424 8.336 14.4351 6.992 16.2439 6.992C18.0527 6.992 19.5454 8.336 19.5454 10C19.5454 11.664 18.0527 13.008 16.2439 13.008Z"
            fill="#20419B" />
        </svg>
        <div>{{ $t("circle_feature.lbl_circle_meet") }}</div>
      </div>
      <div class="cs-item" v-if="
        (isMember || isAdmin) &&
        circleData &&
        circleData.type === CIRCLE_TYPE.NORMAL
      " @click="handleClickConference">
        <svg viewBox="0 0 250 250" width="36" height="36" fill="none" xmlns="http://www.w3.org/2000/svg">
          <g clip-path="url(#clip0_123_32)">
            <path
              d="M118.945 103.132L77.7974 1.53337C76.9659 0.561005 75.7507 0 74.4715 0H15.2866C6.8651 0 0 6.87083 0 15.3178V234.682C0 243.129 6.8651 250 15.2866 250H80.7822C83.1914 250 85.1528 248.039 85.1528 245.624V229.911C85.1528 224.648 86.6026 219.509 89.3422 215.037L93.3824 203.401H82.6051C72.1689 203.401 62.9266 196.727 59.6219 186.795C57.3087 179.818 58.2361 176.28 62.1804 170.077C66.1139 163.88 72.5526 163.863 79.8441 163.025L98.0623 160.925L85.6433 125.759C84.9184 123.996 84.993 122.003 85.8458 120.299C86.7092 118.594 88.2657 117.349 90.1098 116.885L116.685 110.222C118.817 109.688 119.564 108.005 119.788 107.305C120.011 106.61 120.374 104.805 118.945 103.132Z"
              fill="#20419B" />
            <path
              d="M115.854 151.285C113.682 149.113 110.162 149.113 107.99 151.285C105.827 153.457 105.827 156.977 107.999 159.149C113.944 165.099 117.229 173.013 117.229 181.429C117.229 189.84 113.944 197.754 107.999 203.704C105.827 205.876 105.827 209.396 107.99 211.568C109.076 212.654 110.505 213.197 111.926 213.197C113.347 213.197 114.768 212.654 115.854 211.568C123.908 203.518 128.342 192.813 128.342 181.429C128.342 170.04 123.908 159.334 115.854 151.285Z"
              fill="#20419B" />
            <path
              d="M135.509 131.629C133.338 129.457 129.817 129.457 127.645 131.629C125.483 133.801 125.483 137.321 127.654 139.493C138.858 150.692 145.029 165.587 145.029 181.428C145.029 197.269 138.858 212.16 127.654 223.363C125.483 225.535 125.483 229.056 127.654 231.228C128.731 232.313 130.161 232.856 131.582 232.856C133.003 232.856 134.423 232.313 135.509 231.228C148.821 217.925 156.142 200.238 156.142 181.428C156.142 162.614 148.821 144.932 135.509 131.629Z"
              fill="#20419B" />
            <path
              d="M227.726 140.479H215.062V137.313C215.062 136.473 214.728 135.668 214.135 135.074C213.541 134.481 212.736 134.147 211.896 134.147C211.056 134.147 210.251 134.481 209.657 135.074C209.064 135.668 208.73 136.473 208.73 137.313V140.479H196.066C195.226 140.479 194.421 140.813 193.827 141.406C193.233 142 192.9 142.805 192.9 143.645C192.9 144.485 193.233 145.29 193.827 145.884C194.421 146.477 195.226 146.811 196.066 146.811H217.247C216.079 150.156 214.379 153.291 212.213 156.094C210.749 154.631 209.402 153.056 208.185 151.383C207.717 150.689 206.993 150.21 206.172 150.049C205.351 149.887 204.499 150.058 203.803 150.523C203.108 150.988 202.624 151.709 202.459 152.529C202.293 153.35 202.459 154.202 202.92 154.9C204.378 156.955 206.01 158.88 207.799 160.653C204.969 163.011 201.72 164.815 198.222 165.969C197.424 166.234 196.765 166.805 196.388 167.557C196.012 168.309 195.949 169.179 196.215 169.977C196.48 170.775 197.051 171.434 197.803 171.811C198.555 172.187 199.425 172.249 200.223 171.984C204.76 170.498 208.951 168.112 212.545 164.968C215.818 167.632 219.373 169.931 223.145 171.823C223.896 172.198 224.766 172.259 225.563 171.993C225.958 171.862 226.322 171.654 226.636 171.381C226.951 171.108 227.208 170.776 227.394 170.404C227.58 170.032 227.69 169.627 227.72 169.212C227.749 168.797 227.696 168.381 227.564 167.986C227.433 167.592 227.225 167.227 226.952 166.913C226.679 166.599 226.347 166.341 225.975 166.155C222.776 164.541 219.755 162.595 216.962 160.349C220.12 156.325 222.47 151.728 223.883 146.811H227.726C228.566 146.811 229.371 146.477 229.965 145.884C230.559 145.29 230.892 144.485 230.892 143.645C230.892 142.805 230.559 142 229.965 141.406C229.371 140.813 228.566 140.479 227.726 140.479Z"
              fill="#20419B" />
            <path
              d="M240.39 121.483H183.401C180.882 121.483 178.466 122.484 176.685 124.265C174.904 126.046 173.903 128.462 173.903 130.981V175.305C173.903 177.824 174.904 180.24 176.685 182.021C178.466 183.803 180.882 184.803 183.401 184.803H186.567V197.467C186.567 198.033 186.719 198.587 187.006 199.074C187.292 199.561 187.704 199.963 188.199 200.237C188.693 200.511 189.252 200.647 189.817 200.632C190.381 200.618 190.932 200.452 191.411 200.152L215.97 184.803H240.39C242.909 184.803 245.325 183.803 247.106 182.021C248.887 180.24 249.888 177.824 249.888 175.305V130.981C249.888 128.462 248.887 126.046 247.106 124.265C245.325 122.484 242.909 121.483 240.39 121.483ZM243.556 175.305C243.556 176.145 243.222 176.95 242.628 177.544C242.035 178.138 241.229 178.471 240.39 178.471H215.062C214.468 178.471 213.887 178.638 213.384 178.953L192.899 191.769V181.637C192.899 180.798 192.566 179.992 191.972 179.399C191.378 178.805 190.573 178.471 189.733 178.471H183.401C182.562 178.471 181.756 178.138 181.163 177.544C180.569 176.95 180.235 176.145 180.235 175.305V130.981C180.235 130.141 180.569 129.336 181.163 128.742C181.756 128.149 182.562 127.815 183.401 127.815H240.39C241.229 127.815 242.035 128.149 242.628 128.742C243.222 129.336 243.556 130.141 243.556 130.981V175.305Z"
              fill="#20419B" />
            <path
              d="M146.498 41.9097H203.487C206.006 41.9097 208.422 42.9104 210.203 44.6916C211.984 46.4728 212.985 48.8887 212.985 51.4077V95.732C212.985 98.2511 211.984 100.667 210.203 102.448C208.422 104.229 206.006 105.23 203.487 105.23H179.067L154.508 120.579C154.029 120.879 153.479 121.044 152.914 121.059C152.349 121.074 151.79 120.937 151.296 120.663C150.801 120.389 150.389 119.988 150.103 119.501C149.816 119.014 149.664 118.459 149.664 117.894V105.23H146.498C143.979 105.23 141.564 104.229 139.782 102.448C138.001 100.667 137 98.2511 137 95.732V51.4077C137 48.8887 138.001 46.4728 139.782 44.6916C141.564 42.9104 143.979 41.9097 146.498 41.9097ZM143.332 95.732C143.332 96.5717 143.666 97.377 144.26 97.9708C144.853 98.5645 145.659 98.8981 146.498 98.8981H152.83C153.67 98.8981 154.475 99.2316 155.069 99.8254C155.663 100.419 155.996 101.224 155.996 102.064V112.195L176.481 99.392C176.982 99.073 177.564 98.9018 178.159 98.8981H203.487C204.326 98.8981 205.132 98.5645 205.726 97.9708C206.319 97.377 206.653 96.5717 206.653 95.732V51.4077C206.653 50.5681 206.319 49.7628 205.726 49.169C205.132 48.5753 204.326 48.2417 203.487 48.2417H146.498C145.659 48.2417 144.853 48.5753 144.26 49.169C143.666 49.7628 143.332 50.5681 143.332 51.4077V95.732Z"
              fill="#20419B" />
            <path
              d="M177.841 56.3247C177.548 55.834 177.133 55.4277 176.637 55.1456C176.14 54.8634 175.578 54.7151 175.007 54.7151C174.436 54.7151 173.875 54.8634 173.378 55.1456C172.881 55.4277 172.466 55.834 172.174 56.3247L156.344 87.9849C156.158 88.357 156.047 88.7621 156.018 89.177C155.988 89.5919 156.041 90.0085 156.173 90.403C156.304 90.7976 156.513 91.1623 156.785 91.4764C157.058 91.7905 157.39 92.0478 157.762 92.2337C158.134 92.4195 158.539 92.5303 158.954 92.5596C159.369 92.5889 159.786 92.5362 160.18 92.4044C160.575 92.2727 160.939 92.0646 161.253 91.7919C161.568 91.5193 161.825 91.1874 162.011 90.8153L174.991 64.8191L180.95 76.736H174.991C174.152 76.736 173.346 77.0696 172.753 77.6633C172.159 78.2571 171.825 79.0623 171.825 79.902C171.825 80.7417 172.159 81.547 172.753 82.1407C173.346 82.7345 174.152 83.068 174.991 83.068H184.116L187.988 90.8153C188.174 91.1874 188.431 91.5193 188.745 91.7919C189.059 92.0646 189.424 92.2727 189.819 92.4044C190.213 92.5362 190.63 92.5889 191.045 92.5596C191.46 92.5303 191.865 92.4195 192.237 92.2337C192.609 92.0478 192.941 91.7905 193.213 91.4764C193.486 91.1623 193.694 90.7976 193.826 90.403C193.958 90.0085 194.01 89.5919 193.981 89.177C193.952 88.7621 193.841 88.357 193.655 87.9849L177.841 56.3247Z"
              fill="#20419B" />
          </g>
          <defs>
            <clipPath id="clip0_123_32">
              <rect width="250" height="250" fill="white" />
            </clipPath>
          </defs>
        </svg>
        <div class="text-center">
          {{ $t("circle_feature.lbl_circle_multi_lang_meet") }}
        </div>
      </div>

      <div class="cs-item" v-if="isMember || isAdmin" @click="handleClickToRoom">
        <img :src="liveStream" width="36" height="36" />
        <div class="livestream-redot" v-if="circleData && circleData.is_live_stream === 2">
          <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path d="M19.071 19.0709C20.8807 17.2612 22 14.7612 22 11.9998C22 9.23837 20.8807 6.73837 19.071 4.92871"
              stroke="white" stroke-width="2" stroke-linecap="round" />
            <path d="M4.92893 4.92871C3.11929 6.73837 2 9.23837 2 11.9998C2 14.7612 3.11929 17.2612 4.92893 19.0709"
              stroke="white" stroke-width="2" stroke-linecap="round" />
            <path d="M7.55025 7.0498C6.2835 8.31655 5.5 10.0666 5.5 11.9996C5.5 13.9326 6.2835 15.6826 7.55025 16.9493"
              stroke="white" stroke-width="2" stroke-linecap="round" />
            <path
              d="M16.4497 16.9493C17.7165 15.6826 18.5 13.9326 18.5 11.9996C18.5 10.0666 17.7165 8.31655 16.4497 7.0498"
              stroke="white" stroke-width="2" stroke-linecap="round" />
            <path
              d="M12 13.5C12.8284 13.5 13.5 12.8284 13.5 12C13.5 11.1716 12.8284 10.5 12 10.5C11.1716 10.5 10.5 11.1716 10.5 12C10.5 12.8284 11.1716 13.5 12 13.5Z"
              fill="white" />
          </svg>
          {{ $t("lbl_on_air") }}
        </div>
        <div class="text-center">{{ $t("lbl_livestream_room") }}</div>
      </div>
      <div class="cs-item" v-if="isMember || isAdmin" @click="handleOpenVideoList">
        <div class="font-bold fs-30">
          <svg width="36" height="36" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path
              d="M2 5C2 4.44771 2.44771 4 3 4H21C21.5523 4 22 4.44771 22 5V19C22 19.5523 21.5523 20 21 20H3C2.44771 20 2 19.5523 2 19V5Z"
              stroke="#20409B" stroke-width="2" stroke-linejoin="round" />
            <path d="M18 4V20" stroke="#20409B" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" />
            <path d="M6 4V20" stroke="#20409B" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" />
            <path d="M19 9H22" stroke="#20409B" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" />
            <path d="M19 15H22" stroke="#20409B" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" />
            <path d="M2 9H5" stroke="#20409B" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" />
            <path d="M2 8V10" stroke="#20409B" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" />
            <path d="M4.5 4H7.5" stroke="#20409B" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" />
            <path d="M4.5 20H7.5" stroke="#20409B" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" />
            <path d="M16.5 4H19.5" stroke="#20409B" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" />
            <path d="M16.5 20H19.5" stroke="#20409B" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" />
            <path d="M2 15H5" stroke="#20409B" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" />
            <path d="M2 14V16" stroke="#20409B" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" />
            <path d="M22 14V16" stroke="#20409B" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" />
            <path d="M22 8V10" stroke="#20409B" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" />
            <path d="M10.5 9.5L14.5 12L10.5 14.5V9.5Z" stroke="#20409B" stroke-width="2" stroke-linejoin="round" />
          </svg>
        </div>
        <div class="text-center">{{ $t("lbl_video_playlist") }}</div>
      </div>
      <div class="cs-item" v-if="(isMember || isAdmin) && isShowQAndA" @click="handleOpenQAndA">
        <div class="font-bold fs-30">
          <svg width="48" height="48" viewBox="0 0 48 48" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path
              d="M22.25 13.5V30C22.2475 32.3862 21.2985 34.6739 19.6112 36.3612C17.9239 38.0485 15.6362 38.9975 13.25 39C12.8522 39 12.4706 38.842 12.1893 38.5607C11.908 38.2794 11.75 37.8978 11.75 37.5C11.75 37.1022 11.908 36.7206 12.1893 36.4393C12.4706 36.158 12.8522 36 13.25 36C14.8413 36 16.3674 35.3679 17.4926 34.2426C18.6179 33.1174 19.25 31.5913 19.25 30V28.5H8C7.20435 28.5 6.44129 28.1839 5.87868 27.6213C5.31607 27.0587 5 26.2956 5 25.5V13.5C5 12.7044 5.31607 11.9413 5.87868 11.3787C6.44129 10.8161 7.20435 10.5 8 10.5H19.25C20.0456 10.5 20.8087 10.8161 21.3713 11.3787C21.9339 11.9413 22.25 12.7044 22.25 13.5ZM41 10.5H29.75C28.9544 10.5 28.1913 10.8161 27.6287 11.3787C27.0661 11.9413 26.75 12.7044 26.75 13.5V25.5C26.75 26.2956 27.0661 27.0587 27.6287 27.6213C28.1913 28.1839 28.9544 28.5 29.75 28.5H41V30C41 31.5913 40.3679 33.1174 39.2426 34.2426C38.1174 35.3679 36.5913 36 35 36C34.6022 36 34.2206 36.158 33.9393 36.4393C33.658 36.7206 33.5 37.1022 33.5 37.5C33.5 37.8978 33.658 38.2794 33.9393 38.5607C34.2206 38.842 34.6022 39 35 39C37.3862 38.9975 39.6739 38.0485 41.3612 36.3612C43.0485 34.6739 43.9975 32.3862 44 30V13.5C44 12.7044 43.6839 11.9413 43.1213 11.3787C42.5587 10.8161 41.7957 10.5 41 10.5Z"
              fill="#20409B" />
          </svg>
        </div>
        <div class="text-center">{{ $t("Q&A") }}</div>
      </div>
    </div>
    <CircleVideoList ref="CircleVideoList" :circle="circleData" :isAdmin="isAdmin" :isMember="isMember" />
  </div>
</template>

<script>
import liveStream from '@/assets/images/livestream.png'
import CircleVideoList from '@/pages/Circle/CircleVideoList.vue'
import appUtils from '@/utils/appUtils'
import { CIRCLE_TYPE } from '@/utils/constants'
import { mixinGoToChat } from '@/utils/mixinGoToChat'

export default {
  name: 'CircleFeatureList',
  components: { CircleVideoList },
  props: {
    circleData: Object,
    isAdmin: Boolean,
    isMember: Boolean,
    isOwner: Boolean,
    unread_message_count: Number,
    tabsTotalNumber: Object
  },
  mixins: [mixinGoToChat],
  data () {
    return {
      liveStream,
      CIRCLE_TYPE,
      isShowQAndA: false
    }
  },
  computed: {
    hasChatChannelV2 () {
      return (
        this.$conversationsClient &&
        this.circleData?.twilio_conversation?.twilio_conv_id &&
        this.circleData?.migrate_channel_gs?.channel_id
      )
    },
    hasJoinedChatChannelV2 () {
      return (
        !this.circleData?.member_not_joined_chat?.includes(
          String(this.$user?.id)
        ) && this.circleData?.request_chat === 2
      )
    },
    hasSendRequestChatV2 () {
      return this.circleData?.request_chat === 1
    }
  },
  methods: {
    goToChat (e, circle) {
      if (
        !this.hasChatChannelV2 ||
        (!this.hasJoinedChatChannelV2 && !this.isOwner)
      ) {
        return
      }

      const twilio_conv_id = circle?.twilio_conversation?.twilio_conv_id
      this.setSelectedCircle(circle)
      this.messageToGroup(twilio_conv_id, e)
      // if (!this.circleData?.id) return
      // this.$router.push({
      //   name: 'CircleChat',
      //   params: { id: this.circleData.id }
      // })
    },
    goToAttachment () {
      this.$router.push({
        name: 'CircleDocuments',
        params: { id: this.$route.params.id }
      })
    },
    async goToMeet () {
      if (!this.circleData?.meet_room_id) {
        alert(this.$t('lbl_meet_circle_does_not_exist'))
        return
      }
      const meeting_id = this.circleData.meet_room_id
      let res = await this.$rf
        .getRequest('DoctorRequest')
        .showMeetRoom(meeting_id)
      if (res.data) {
        const token = appUtils.getLocalToken()

        if (appUtils.isUseLiveHodoV2('meet')) {
          let domainOrigin = window.location?.origin
          let domain = ''

          if (domainOrigin?.includes('http://localhost')) {
            domain = 'http://localhost:8068'
          } else if (
            process.env.NODE_ENV === 'development' &&
            !domainOrigin?.includes('http://localhost')
          ) {
            domain = 'https://dev.v2.live.hodo.app/'
          } else {
            domain = 'https://v2.live.hodo.app'
          }
          // const role = roleObj['conference']
          const room100ms_id = res.data.room?.room100ms_id
          const routeRaw = `${domain}/redirect.html?token=${token}&room_id=${room100ms_id}&circle_id=${this.circleData?.id
          }&meet_room_id=${this.circleData?.meet_room_id
          }&type=meeting&role=${'host'}&lang=${this.$lang}`

          console.log(routeRaw)

          let linkElement = document.createElement('a')
          const linkId = `meeting_${room100ms_id}`
          linkElement.id = linkId
          linkElement.href = routeRaw
          window.document.body.appendChild(linkElement)

          const linkEl = document.getElementById(linkId)
          linkEl.setAttribute('rel', 'noopener noreferrer')
          !appUtils.checkIsMobile() && linkEl.setAttribute('target', '_blank')
          linkEl.click()
          linkEl.remove()

          // window.open(routeRaw, "_blank");
        } else {
          let dm = ''
          if (process.env.NODE_ENV === 'development') {
            dm = 'https://dev.meet.hodo.com.vn/#/'
          } else {
            dm = 'https://meet.hodo.com.vn/#/'
          }
          let routeData =
            dm + '_app/' + res.data?.room?.room_name + '?token=' + token
          window.open(routeData, '_blank')
        }
      }
    },
    handleOpenVideoList () {
      this.$refs.CircleVideoList.openModal(true)
    },
    handleOpenQAndA () {
      if (!this.circleData?.id) return

      this.$router.push({
        name: 'CircleQAndA',
        params: {
          id: this.circleData.id
        }
      })
    },
    handleClickToRoom () {
      if (!this.circleData?.id) return

      this.$router.push({
        name: 'CircleLiveDetail',
        params: {
          id: this.circleData.id
        }
      })
    },
    handleClickConference () {
      if (!this.circleData?.id) return

      this.$router.push({
        name: 'CircleConferenceDetail',
        params: {
          id: this.circleData.id
        }
      })
    },
    async handleRequestJoinChatChannel () {
      if (!this.circleData?.id || this.hasSendRequestChatV2) return

      try {
        const params = {
          circle_id: this.circleData.id
        }

        const response = await this.$rf
          .getRequest('DoctorRequest')
          .requestJoinChatCircle(params)

        console.log(response)

        if (response.status === 200) {
          this.$emit('onRefresh')
          this.$toast.open({
            message: this.$t('Gửi yêu cầu tham gia Chat thành công!'),
            type: 'success'
          })
        }
      } catch (error) {
        console.log(error)
        this.$toast.open({
          message: this.$t('Gửi yêu cầu tham gia Chat thất bại'),
          type: 'error'
        })
      }
    }
  }
}
</script>

<style lang="scss" scoped>
.livestream-redot {
  background-color: #d92d20;
  color: white;
  padding: 2px 6px;
  border-radius: 6px;
  font-size: 12px;
  font-weight: 500;
  width: fit-content;
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 2px;
  cursor: pointer;
}

.cs-item {
  width: fit-content;
  min-width: 200px;
  min-height: 120px;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  margin-bottom: 16px;
  background-color: white;
  border-radius: 10px;
  box-shadow: -1px -2px 10px 2px rgba(230, 230, 230, 0.75);
  cursor: pointer;
  font-size: 20px;
  gap: 8px;
  padding: 8px;

  @media (max-width: 1350px) {
    width: 150px;
    min-width: unset;
    font-size: 18px;
  }
}

.custom:hover {
  background-color: #e4e7ec;
}

.px {
  padding: 9px 43px;
}

.ml-40 {
  margin-left: 40px;
}

.container {
  width: 1024px;
}

.p-48 {
  padding: 0px 48px 0px 48px;
}

.white-s-pre {
  white-space: pre-wrap;
}

.icinfo {
  margin-right: 16px;
}

.bd-blur-gray {
  border-color: #98a2b3;
}

.nav-item.active a {
  color: #20419b !important;
  font-weight: 500;
}

.modal-dialog.modal-qr {
  position: relative;
  display: table;
  /* This is important */
  overflow-y: auto;
  overflow-x: auto;
  width: auto;
  min-width: 300px;
}

.btn-FEF3F2 {
  background-color: #fef3f2;
  color: #b42318;
  border-color: #fef3f2;
}

.btn-add-member {
  width: 100%;
  display: flex;
  align-items: center;
  border-radius: 10px;
  border: 1px dashed #20419b;
  font-weight: 400;
  font-size: 14px;
  line-height: 20px;
  /* identical to box height, or 143% */

  /* Primary */

  color: #20419b;
  padding: 8px;
  cursor: pointer;
}

.open-chat-att {
  padding: 16px;
  display: flex;
  align-items: center;
  background-color: #fafafa;
  width: 100%;
  border-radius: 10px;
  cursor: pointer;
  margin-bottom: 16px;
  position: relative;
  transition: all 0.3s ease;

  &:hover {
    background-color: #eff4ff;
  }
}

.has-unread-chat {
  background-color: #f2f6ff;
  border: 1px solid #20419b;
}

.red-dot-new {
  position: absolute;
  top: 5px;
  right: 10px;
}

.disabled {
  opacity: 0.6;
  cursor: not-allowed;
  pointer-events: none;
}

.loading-btn-icon {
  display: none;
}

.loading-btn .loading-btn-icon {
  display: block !important;
}

.loading-btn {
  pointer-events: none;
  color: white;
  border-color: #98a2b3 !important;
}

.loading-btn span {
  display: none;
}

.livestream-redot {
  background-color: #d92d20;
  color: white;
  padding: 1px 8px;
  border-radius: 10px;
  font-size: 10px;
  font-weight: 500;
}

.chat-icon {
  position: absolute;
  top: 0;
  right: 0;
  background-color: #b3d8ff;
  padding: 4px 8px;
  border-radius: 0% 0% 25% 12% / 25% 25% 0% 12%;
}

.request-chat {
  font-size: 14px;
  padding: 4px 8px;
  background-color: #f4af48;
  border-radius: 4px;
  cursor: pointer;
  font-weight: 400;
  box-shadow: 0px 1px 14px -6px rgba(0, 0, 0, 0.24);
  -webkit-box-shadow: 0px 1px 14px -6px rgba(0, 0, 0, 0.24);
  -moz-box-shadow: 0px 1px 14px -6px rgba(0, 0, 0, 0.24);
  min-width: 120px;
  text-align: center;
}

.cursor-default {
  cursor: default !important;
}

.send-request {
  background-color: #2fe02c;
  cursor: default;
}
</style>
