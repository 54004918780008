<template>
  <div>
    <div class="flex gap-2 items-center mb-2">
      <span
        class="text-black font-bold fs-18 cursor-pointer"
        @click="handleBackToList"
        >{{ $t("lbl_list_circle") }}</span
      >
      /
      <span>{{ $t("lbl_circle_information") }}</span>
    </div>
    <!-- <MigratedChatV2
      :isOwner="isOwner"
      :circleData="circleData"
      @onRefresh="handleRefresh"
    /> -->

    <div
      class="bg-white rounded-lg flex flex-col justify-between cs-box-shadow relative"
    >
      <div class="flex justify-end items-center p-2 pb-0">
        <div class="flex items-start">
          <el-tooltip
            class="item"
            effect="dark"
            :content="$t('lbl_edit_circle_information')"
            placement="top"
          >
            <div
              v-if="isAdmin"
              @click="handleOpenEditModal"
              class="cursor-pointer"
            >
              <svg
                width="24"
                height="24"
                viewBox="0 0 24 24"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path
                  d="M21 11.9999C20.7348 11.9999 20.4804 12.1053 20.2929 12.2928C20.1054 12.4804 20 12.7347 20 12.9999V18.9999C20 19.2652 19.8946 19.5195 19.7071 19.707C19.5196 19.8946 19.2652 19.9999 19 19.9999H5C4.73478 19.9999 4.48043 19.8946 4.29289 19.707C4.10536 19.5195 4 19.2652 4 18.9999V4.99994C4 4.73472 4.10536 4.48037 4.29289 4.29283C4.48043 4.1053 4.73478 3.99994 5 3.99994H11C11.2652 3.99994 11.5196 3.89458 11.7071 3.70705C11.8946 3.51951 12 3.26516 12 2.99994C12 2.73472 11.8946 2.48037 11.7071 2.29283C11.5196 2.1053 11.2652 1.99994 11 1.99994H5C4.20435 1.99994 3.44129 2.31601 2.87868 2.87862C2.31607 3.44123 2 4.20429 2 4.99994V18.9999C2 19.7956 2.31607 20.5587 2.87868 21.1213C3.44129 21.6839 4.20435 21.9999 5 21.9999H19C19.7956 21.9999 20.5587 21.6839 21.1213 21.1213C21.6839 20.5587 22 19.7956 22 18.9999V12.9999C22 12.7347 21.8946 12.4804 21.7071 12.2928C21.5196 12.1053 21.2652 11.9999 21 11.9999ZM6 12.7599V16.9999C6 17.2652 6.10536 17.5195 6.29289 17.707C6.48043 17.8946 6.73478 17.9999 7 17.9999H11.24C11.3716 18.0007 11.5021 17.9755 11.6239 17.9257C11.7457 17.8759 11.8566 17.8026 11.95 17.7099L18.87 10.7799L21.71 7.99994C21.8037 7.90698 21.8781 7.79637 21.9289 7.67452C21.9797 7.55266 22.0058 7.42195 22.0058 7.28994C22.0058 7.15793 21.9797 7.02722 21.9289 6.90536C21.8781 6.7835 21.8037 6.6729 21.71 6.57994L17.47 2.28994C17.377 2.19621 17.2664 2.12182 17.1446 2.07105C17.0227 2.02028 16.892 1.99414 16.76 1.99414C16.628 1.99414 16.4973 2.02028 16.3754 2.07105C16.2536 2.12182 16.143 2.19621 16.05 2.28994L13.23 5.11994L6.29 12.0499C6.19732 12.1434 6.12399 12.2542 6.07423 12.376C6.02446 12.4979 5.99924 12.6283 6 12.7599ZM16.76 4.40994L19.59 7.23994L18.17 8.65994L15.34 5.82994L16.76 4.40994ZM8 13.1699L13.93 7.23994L16.76 10.0699L10.83 15.9999H8V13.1699Z"
                  fill="#20409B"
                />
              </svg>
            </div>
          </el-tooltip>
        </div>
      </div>

      <div class="flex flex-col md:flex-row gap-3 p-4 pt-3">
        <img
          v-if="circleData.logo"
          class="avatar w-40 h-40 object-cover object-top border"
          :src="circleData.logo"
          :alt="circleData.name"
        />
        <el-skeleton-item
          v-else
          variant="image"
          style="width: 112px; height: 112px"
        />

        <div class="flex flex-col gap-1">
          <div class="flex items-center mb-2">
            <h5 class="fs-24 font-bold text-black m-0">
              {{ circleData.name }}
            </h5>
            <div
              class="livestream-redot ml-4"
              @click="goToStream"
              v-if="circleData && circleData.is_live_stream === 2"
            >
              <svg
                width="24"
                height="24"
                viewBox="0 0 24 24"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path
                  d="M19.071 19.0709C20.8807 17.2612 22 14.7612 22 11.9998C22 9.23837 20.8807 6.73837 19.071 4.92871"
                  stroke="white"
                  stroke-width="2"
                  stroke-linecap="round"
                />
                <path
                  d="M4.92893 4.92871C3.11929 6.73837 2 9.23837 2 11.9998C2 14.7612 3.11929 17.2612 4.92893 19.0709"
                  stroke="white"
                  stroke-width="2"
                  stroke-linecap="round"
                />
                <path
                  d="M7.55025 7.0498C6.2835 8.31655 5.5 10.0666 5.5 11.9996C5.5 13.9326 6.2835 15.6826 7.55025 16.9493"
                  stroke="white"
                  stroke-width="2"
                  stroke-linecap="round"
                />
                <path
                  d="M16.4497 16.9493C17.7165 15.6826 18.5 13.9326 18.5 11.9996C18.5 10.0666 17.7165 8.31655 16.4497 7.0498"
                  stroke="white"
                  stroke-width="2"
                  stroke-linecap="round"
                />
                <path
                  d="M12 13.5C12.8284 13.5 13.5 12.8284 13.5 12C13.5 11.1716 12.8284 10.5 12 10.5C11.1716 10.5 10.5 11.1716 10.5 12C10.5 12.8284 11.1716 13.5 12 13.5Z"
                  fill="white"
                />
              </svg>
              {{ $t("lbl_on_air") }}
            </div>
          </div>
          <p
            v-if="circleData.description"
            class="font-normal whitespace-pre-wrap"
          >
            {{ circleData.description }}
          </p>
          <div class="flex gap-4 items-end">
            <div class="flex gap-4 items-end">
              <p class="fs-14 fw-600 m-0">
                {{ $t("circle_feature.lbl_circle_code") }}:
              </p>
              <p class="fs-16 fw-600 m-0 txt-pri leading-5">
                {{ circleData && circleData.code }}
              </p>
            </div>
            <el-tooltip
              class="item"
              effect="dark"
              content="Copy Code"
              placement="top"
            >
              <svg
                class="cursor-pointer"
                width="24"
                height="24"
                viewBox="0 0 24 24"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
                @click="copyCode(circleData && circleData.code)"
              >
                <path
                  d="M16.5 1H4.5C3.4 1 2.5 1.9 2.5 3V17H4.5V3H16.5V1ZM19.5 5H8.5C7.4 5 6.5 5.9 6.5 7V21C6.5 22.1 7.4 23 8.5 23H19.5C20.6 23 21.5 22.1 21.5 21V7C21.5 5.9 20.6 5 19.5 5ZM19.5 21H8.5V7H19.5V21Z"
                  fill="#1D2939"
                />
              </svg>
            </el-tooltip>
            <el-tooltip
              class="item"
              effect="dark"
              content="QR Code"
              placement="top"
            >
              <svg
                class="cursor-pointer"
                width="25"
                height="25"
                viewBox="0 0 25 25"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
                @click="showModal(true, '#modalQrcode')"
              >
                <path
                  d="M20.0945 16.6572H17.0947C16.8876 16.6572 16.7197 16.8251 16.7197 17.0322V20.032C16.7197 20.2391 16.8876 20.4069 17.0947 20.4069H20.0945C20.3016 20.4069 20.4694 20.2391 20.4694 20.032V17.0322C20.4694 16.8251 20.3016 16.6572 20.0945 16.6572Z"
                  fill="black"
                />
                <path
                  d="M16.3465 13.6572H14.0967C13.8896 13.6572 13.7217 13.8251 13.7217 14.0322V16.282C13.7217 16.4891 13.8896 16.657 14.0967 16.657H16.3465C16.5536 16.657 16.7215 16.4891 16.7215 16.282V14.0322C16.7215 13.8251 16.5536 13.6572 16.3465 13.6572Z"
                  fill="black"
                />
                <path
                  d="M23.0955 20.4062H20.8457C20.6386 20.4062 20.4707 20.5741 20.4707 20.7812V23.0311C20.4707 23.2381 20.6386 23.406 20.8457 23.406H23.0955C23.3026 23.406 23.4705 23.2381 23.4705 23.0311V20.7812C23.4705 20.5741 23.3026 20.4062 23.0955 20.4062Z"
                  fill="black"
                />
                <path
                  d="M23.0936 13.6572H21.5937C21.3866 13.6572 21.2188 13.8251 21.2188 14.0322V15.5321C21.2188 15.7392 21.3866 15.9071 21.5937 15.9071H23.0936C23.3007 15.9071 23.4686 15.7392 23.4686 15.5321V14.0322C23.4686 13.8251 23.3007 13.6572 23.0936 13.6572Z"
                  fill="black"
                />
                <path
                  d="M15.5965 21.1572H14.0967C13.8896 21.1572 13.7217 21.3251 13.7217 21.5322V23.0321C13.7217 23.2392 13.8896 23.4071 14.0967 23.4071H15.5965C15.8036 23.4071 15.9715 23.2392 15.9715 23.0321V21.5322C15.9715 21.3251 15.8036 21.1572 15.5965 21.1572Z"
                  fill="black"
                />
                <path
                  d="M20.0945 5.4082H17.0947C16.8876 5.4082 16.7197 5.57608 16.7197 5.78317V8.78295C16.7197 8.99004 16.8876 9.15792 17.0947 9.15792H20.0945C20.3016 9.15792 20.4694 8.99004 20.4694 8.78295V5.78317C20.4694 5.57608 20.3016 5.4082 20.0945 5.4082Z"
                  fill="black"
                />
                <path
                  d="M21.9711 3.1582H15.2216C14.8074 3.1582 14.4717 3.49396 14.4717 3.90815V10.6576C14.4717 11.0718 14.8074 11.4076 15.2216 11.4076H21.9711C22.3853 11.4076 22.7211 11.0718 22.7211 10.6576V3.90815C22.7211 3.49396 22.3853 3.1582 21.9711 3.1582Z"
                  stroke="black"
                  stroke-width="1.49989"
                  stroke-linecap="round"
                  stroke-linejoin="round"
                />
                <path
                  d="M8.84545 5.4082H5.84567C5.63858 5.4082 5.4707 5.57608 5.4707 5.78317V8.78295C5.4707 8.99004 5.63858 9.15792 5.84567 9.15792H8.84545C9.05254 9.15792 9.22042 8.99004 9.22042 8.78295V5.78317C9.22042 5.57608 9.05254 5.4082 8.84545 5.4082Z"
                  fill="black"
                />
                <path
                  d="M10.7211 3.1582H3.97162C3.55744 3.1582 3.22168 3.49396 3.22168 3.90815V10.6576C3.22168 11.0718 3.55744 11.4076 3.97162 11.4076H10.7211C11.1353 11.4076 11.4711 11.0718 11.4711 10.6576V3.90815C11.4711 3.49396 11.1353 3.1582 10.7211 3.1582Z"
                  stroke="black"
                  stroke-width="1.49989"
                  stroke-linecap="round"
                  stroke-linejoin="round"
                />
                <path
                  d="M8.84545 16.6572H5.84567C5.63858 16.6572 5.4707 16.8251 5.4707 17.0322V20.032C5.4707 20.2391 5.63858 20.4069 5.84567 20.4069H8.84545C9.05254 20.4069 9.22042 20.2391 9.22042 20.032V17.0322C9.22042 16.8251 9.05254 16.6572 8.84545 16.6572Z"
                  fill="black"
                />
                <path
                  d="M10.7211 14.4072H3.97162C3.55744 14.4072 3.22168 14.743 3.22168 15.1572V21.9067C3.22168 22.3208 3.55744 22.6566 3.97162 22.6566H10.7211C11.1353 22.6566 11.4711 22.3208 11.4711 21.9067V15.1572C11.4711 14.743 11.1353 14.4072 10.7211 14.4072Z"
                  stroke="black"
                  stroke-width="1.49989"
                  stroke-linecap="round"
                  stroke-linejoin="round"
                />
              </svg>
            </el-tooltip>
          </div>
          <div class="flex items-center gap-4 mt-2">
            <div class="flex items-center gap-1">
              <svg
                width="24"
                height="24"
                viewBox="0 0 24 24"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path
                  d="M12.5947 18.2565V13.6353M12.5947 12.6891C12.8067 10.038 11.3857 5.02198 4.00492 6.16728C3.89887 8.34122 5.46835 12.6891 12.5947 12.6891ZM12.5947 12.6891C13.7081 11.5226 15.723 8.56014 20.5 10C20.0654 12.0378 18.3162 14.7488 12.5947 13.6353M12.5947 12.6891V13.6353"
                  stroke="#84818A"
                  stroke-width="2"
                />
                <rect x="3" y="18" width="17" height="2" fill="#84818A" />
              </svg>
              <span class="fs-14 fw-600 m-0 mt-1">Seed</span>
            </div>

            <div class="flex items-center">
              <div
                class="flex items-center gap-2"
                v-if="circleData && circleData.seed_info"
              >
                <div style="min-width: 30px">
                  <ImageBox
                    v-if="circleData.seed_info && circleData.seed_info.avatar"
                    class="avatar avatar-sm avatar-rounded object-cover object-top border"
                    avatar
                    :source="
                      circleData.seed_info &&
                      getImageURL(circleData.seed_info.avatar)
                    "
                    :alt="circleData.seed_info.name"
                  />
                  <span
                    v-if="!circleData.seed_info || !circleData.seed_info.avatar"
                    class="avatar avatar-sm avatar-rounded bg-pri text-white"
                  >
                    {{
                      circleData.seed_info.name.charAt(0) ||
                      circleData.seed_info.email.charAt(0)
                    }}
                  </span>
                </div>
                <div class="flex-1">
                  <span class="fs-16 fw-600 txt-black">{{
                    circleData.seed_info.name
                  }}</span>
                </div>
              </div>
            </div>
          </div>
        </div>
        <template v-if="false">
          <button
            v-if="!isOwner"
            type="button"
            class="btn btn-FEF3F2 mr-2 w-md-100 justify-self-end"
            @click="leaveCircle()"
          >
            {{ $t("circle_feature.btn_leave_circle") }}
          </button>
        </template>
      </div>
    </div>
    <div
      class="modal fade"
      id="modalQrcode"
      tabindex="-1"
      role="dialog"
      aria-labelledby="modalQrcodeLabel"
      aria-hidden="true"
    >
      <div class="modal-dialog modal-qr modal-dialog-centered" role="document">
        <div class="modal-content">
          <qrcode
            v-if="circleData && circleData.code"
            :value="circleData.code"
            :options="{
              width: 280,
              margin: 1,
              color: {
                dark: '#20419B',
                light: '#ffffff',
              },
            }"
            style="margin: 0 auto; padding: 6px"
            class
            :text="circleData.code"
          ></qrcode>
        </div>
      </div>
    </div>
    <ModalEditCircle ref="ModalEditCircle" @onRefresh="handleRefresh" />
  </div>
</template>

<script>
import appUtils from '../../../utils/appUtils'
import ModalEditCircle from './ModalEditCircle.vue'
import ImageBox from '@/components/Common/Image.vue'
import MigratedChatV2 from './MigrateChatV2.vue'

export default {
  name: 'CircleInfo',
  components: { ModalEditCircle, ImageBox, MigratedChatV2 },
  props: {
    circleData: Object,
    isAdmin: Boolean,
    isMember: Boolean,
    isOwner: Boolean
  },
  data () {
    return {
      user_100ms_role: 'viewer',
      isDevMode: process.env.NODE_ENV === 'development',
      isLoading: false
    }
  },
  computed: {
    isMigrated () {
      return this.circleData?.migrate_channel_gs?.channel_id
    },
    orgInfo () {
      return this.$globalClinic?.organization || {}
    }
  },
  methods: {
    async leaveCircle () {
      let confirm = this.$confirm(
        'Một khi rời khỏi circle, bạn sẽ không thể xem thông tin về Circle và không thể tham gia trò chuyện. Vẫn tiếp tục?',
        'Cảnh báo',
        {
          confirmButtonText: 'OK',
          cancelButtonText: 'Hủy',
          type: 'warning'
        }
      )
        .then(async () => {
          if (confirm) {
            await this.$rf
              .getRequest('DoctorRequest')
              .leaveCircle({ circle_id: this.circleData.id })
              .then(() => {
                this.handleBackToList()
                this.$toast.open({
                  message: this.$t('Rời khỏi Circle thành công'),
                  type: 'success'
                })
              })
              .catch((e) => {
                console.log(e)
                this.$toast.open({
                  message: e,
                  type: 'error'
                })
              })
          }
        })
        .catch(() => {
          this.$message({
            type: 'info',
            message: 'Hủy rời Circle'
          })
        })
    },
    handleOpenEditModal () {
      this.$refs.ModalEditCircle.handleOpen(this.circleData)
    },
    handleRefresh () {
      this.$emit('onRefresh')
    },
    showModal (show, modalRef) {
      window.$(modalRef).modal(show ? 'show' : 'hide')
    },
    copyCode (code) {
      let el_code = document.createElement('input')
      document.body.appendChild(el_code)
      el_code.value = code
      el_code.select()
      el_code.setSelectionRange(0, 99999)
      document.execCommand('copy')
      this.$toast.open({
        message: this.$t('multidisciplinary_board.lbl_copied_clipboard'),
        type: 'success'
      })
      el_code.remove()
    },
    handleBackToList () {
      this.$router.replace({
        name: 'CircleList'
      })
    },
    getImageURL (a) {
      return appUtils.getImageURL(a)
    },
    async goToStream () {
      if (!this.circleData?.live_room_id) {
        alert(this.$t('circle_feature.lbl_live_circle_not_exist'))
        return
      }

      const { id, live_room_id } = this.circleData
      await this.get100msRole().then(() => {
        let token = appUtils.getLocalToken()
        // let dm = 'http://localhost:3000'
        let dm = ''
        if (process.env.NODE_ENV === 'development') {
          dm = 'https://dev.live.hodo.app'
        } else {
          dm = 'https://live.hodo.app'
        }

        const roleObj = appUtils.isJSON(this.user_100ms_role)
          ? JSON.parse(this.user_100ms_role)
          : {
            livestream: this.user_100ms_role
          }
        const role = roleObj['livestream']
        const routeRaw = `${dm}/redirect.html?token=${token}&room_id=${live_room_id}&circle_id=${id}&type=streaming&role=${
          role || 'viewer'
        }&lang=${this.$lang}`

        console.log(routeRaw)

        let linkElement = document.createElement('a')
        const linkId = `conf_${live_room_id}`
        linkElement.id = linkId
        linkElement.href = routeRaw
        window.document.body.appendChild(linkElement)

        const linkEl = document.getElementById(linkId)
        linkEl.setAttribute('rel', 'noopener noreferrer')
        !appUtils.checkIsMobile() && linkEl.setAttribute('target', '_blank')
        linkEl.click()
        linkEl.remove()

        // window.open(routeRaw, '_blank')
      })
    },
    async get100msRole () {
      if (!this.circleData?.id || !this.$user?.id) return

      await this.$rf
        .getRequest('DoctorRequest')
        .checkMyRoleConferenceRoom({
          circle_id: this.circleData.id,
          user_id: this.$user.id
        })
        .then((res) => {
          this.user_100ms_role =
            res.data?.one_hundred_ms_role ||
            JSON.stringify({
              livestream: 'viewer'
            })
        })
        .catch((e) => {
          console.log(e)
          this.$toast.open({
            message: e,
            type: 'error'
          })
        })
    }
  }
}
</script>

<style lang="scss" scoped>
.livestream-redot {
  background-color: #d92d20;
  color: white;
  padding: 4px 10px;
  border-radius: 8px;
  font-size: 15px;
  font-weight: 500;
  width: fit-content;
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 4px;
  cursor: pointer;
}
.btn-FEF3F2 {
  background-color: #b42318;
  color: #fef3f2;
  border-color: #df1a0c;
  margin-left: auto;
  height: fit-content;
}
</style>
