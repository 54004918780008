<template>
  <div v-loading="isLoading" class="mt-10 bg-white p-4 rounded-lg">
    <div class="col-12">
      <template>
        <div class="d-flex">
          <div class="icinfo">
            <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
              <path d="M16.67 13.13C18.04 14.06 19 15.32 19 17V20H23V17C23 14.82 19.43 13.53 16.67 13.13Z"
                fill="#84818A" />
              <path
                d="M15 12C17.21 12 19 10.21 19 8C19 5.79 17.21 4 15 4C14.53 4 14.09 4.1 13.67 4.24C14.5 5.27 15 6.58 15 8C15 9.42 14.5 10.73 13.67 11.76C14.09 11.9 14.53 12 15 12Z"
                fill="#84818A" />
              <path
                d="M9 12C11.21 12 13 10.21 13 8C13 5.79 11.21 4 9 4C6.79 4 5 5.79 5 8C5 10.21 6.79 12 9 12ZM9 6C10.1 6 11 6.9 11 8C11 9.1 10.1 10 9 10C7.9 10 7 9.1 7 8C7 6.9 7.9 6 9 6Z"
                fill="#84818A" />
              <path
                d="M9 13C6.33 13 1 14.34 1 17V20H17V17C17 14.34 11.67 13 9 13ZM15 18H3V17.01C3.2 16.29 6.3 15 9 15C11.7 15 14.8 16.29 15 17V18Z"
                fill="#84818A" />
            </svg>
          </div>
          <div class="row">
            <p class="fs-14 fw-600 mb-0 mt-1">
              {{ $t("circle_feature.lbl_circle_member") }}
            </p>
          </div>
        </div>
      </template>
      <nav class="navbar navbar-expand-lg navbar-light bg-white" v-if="isAdmin">
        <a class="navbar-brand d-lg-none" href="#">
          {{
            type === 0
              ? this.$t("circle_feature.lbl_request_to_join")
              : type === 1
                ? this.$t("circle_feature.lbl_invited_list")
                : type === 2
                  ? this.$t("circle_feature.lbl_participant")
                  : type === 2
                    ? this.$t("circle_feature.lbl_board_of_administrators")
                    : ""
          }}
        </a>
        <button class="navbar-toggler" type="button" data-toggle="collapse" data-target="#navbarSupportedContent"
          aria-controls="navbarSupportedContent" aria-expanded="false" aria-label="Toggle navigation">
          <span class="navbar-toggler-icon"></span>
        </button>

        <div class="collapse navbar-collapse" id="navbarSupportedContent">
          <ul class="navbar-nav mr-auto">
            <li class="nav-item" :class="type === 2 ? 'active' : ''">
              <a class="nav-link fs-14" href="javascript:;" @click="changeMemType(2)">
                {{ $t("circle_feature.lbl_participant") }} ({{
                  tabsTotalNumber.member_count
                }})
              </a>
            </li>
            <li class="nav-item" :class="type === 3 ? 'active' : ''">
              <a class="nav-link fs-14" href="javascript:;" @click="changeMemType(3)">
                {{ $t("circle_feature.lbl_board_of_administrators") }} ({{
                  tabsTotalNumber.admin_count
                }})
              </a>
            </li>
            <li class="nav-item" :class="type === 1 ? 'active' : ''">
              <a class="nav-link fs-14" href="javascript:;" @click="changeMemType(1)">
                {{ $t("circle_feature.lbl_invited_list") }} ({{
                  tabsTotalNumber.invited_count
                }})
              </a>
            </li>
            <li class="nav-item" :class="type === 0 ? 'active' : ''">
              <a class="nav-link fs-14" href="javascript:;" @click="changeMemType(0)">
                {{ $t("circle_feature.lbl_request_to_join") }} ({{
                  tabsTotalNumber.pending_count
                }})
              </a>
            </li>
          </ul>
        </div>
      </nav>
      <div class="table-responsive">
        <table v-if="members" class="table table-vcenter table-mobile-md card-table no--border table-patient-list">
          <thead>
            <tr>
              <th style="width: 30%">
                <span class="txt-grey-600 fs-14 fw-600 whitespace-nowrap">{{
                  $t("circle_feature.lbl_information")
                }}</span>
              </th>
              <th style="width: 30%">
                <span class="txt-grey-600 fs-14 fw-600 whitespace-nowrap">{{
                  $t("circle_feature.lbl_position")
                }}</span>
              </th>
              <th style="width: 30%">
                <span class="txt-grey-600 fs-14 fw-600 whitespace-nowrap">{{
                  $t("circle_feature.lbl_workplace")
                }}</span>
              </th>
              <th style="width: 10%">
                <span class="txt-grey-600 fs-14 fw-600 whitespace-nowrap" v-if="isAdmin">{{
                  $t("circle_feature.lbl_action")
                }}</span>
              </th>
            </tr>
          </thead>
          <tbody>
            <tr v-if="isAdmin">
              <td colspan="4">
                <div class="btn-add-member" @click="showModal(true, '#modalAddCMem')">
                  <svg width="42" height="42" viewBox="0 0 42 42" fill="none" xmlns="http://www.w3.org/2000/svg"
                    class="mr-2">
                    <path opacity="0.1" fill-rule="evenodd" clip-rule="evenodd"
                      d="M21 42C32.598 42 42 32.598 42 21C42 9.40202 32.598 0 21 0C9.40202 0 0 9.40202 0 21C0 32.598 9.40202 42 21 42Z"
                      fill="#20419B" />
                    <path fill-rule="evenodd" clip-rule="evenodd"
                      d="M21.84 20.1595H27.72C28.1839 20.1595 28.56 20.5355 28.56 20.9995C28.56 21.4634 28.1839 21.8395 27.72 21.8395H21.84V27.7195C21.84 28.1834 21.4639 28.5595 21 28.5595C20.5361 28.5595 20.16 28.1834 20.16 27.7195V21.8395H14.28C13.8161 21.8395 13.44 21.4634 13.44 20.9995C13.44 20.5355 13.8161 20.1595 14.28 20.1595H20.16V14.2795C20.16 13.8155 20.5361 13.4395 21 13.4395C21.4639 13.4395 21.84 13.8155 21.84 14.2795V20.1595Z"
                      fill="#20419B" />
                  </svg>
                  <span>{{ $t("circle_feature.btn_add_member") }}</span>
                </div>
              </td>
            </tr>
            <tr v-for="member in members" class="cursor-pointer custom" :key="member.id"
              :set="(user = member.user_info)">
              <td v-if="user" :data-label="$t('circle_feature.lbl_information')" @click="
                goToProfile(
                  member.user_info &&
                  member.user_info.doctor_infor &&
                  member.user_info.doctor_infor.id
                )
                ">
                <div class="px-3 flex items-center w-full">
                  <div class="mr-2">
                    <ImageBox v-if="user.avatar" class="avatar avatar-rounded object-cover object-top border" avatar
                      :source="getImageURL(user.avatar)" :alt="user.name || user.email" />
                    <span v-if="!user.avatar" class="avatar avatar-rounded mr-0">
                      {{
                        user.name
                          ? user.name.charAt(0)
                          : user.email
                            ? user.email.charAt(0)
                            : "H"
                      }}
                    </span>
                  </div>
                  <div>
                    <p class="flex-1 mb-0 fs-14 fw-600">
                      {{ user.name || user.email }}
                    </p>
                    <p v-if="type === 2 && checkIsAdmin(member)" class="text-blue-900 font-bold mt-1 mb-0">
                      {{ $t("circle_feature.lbl_board_of_administrators") }}
                    </p>
                  </div>
                </div>
              </td>

              <td v-if="user" :data-label="$t('circle_feature.lbl_position')">
                <p class="mb-0 fs-14 fw-500">
                  {{
                    user.chuc_vu ||
                    (user.doctor_infor && user.doctor_infor.chuc_vu)
                  }}
                </p>
              </td>
              <td v-if="user" :data-label="$t('circle_feature.lbl_workplace')">
                <p class="mb-0 fs-14 fw-500">
                  {{ user && user.doctor_infor && user.doctor_infor.work }}
                </p>
              </td>
              <td v-if="user" :data-label="$t('circle_feature.lbl_action')">
                <div class="flex flex-col gap-2 md:flex-row w-full" style="white-space: nowrap">
                  <button type="button" class="btn bg--babyblue txt-pri mr-2 border-0"
                    v-if="type === 2 && $user && user.id != $user.id"
                    :class="isNotMigrateVirgil(member) ? 'disabled pointer-events-none text-black' : 'txt-pri'"
                    @click="messageToDirect(member.user_id, $event, member)" :disabled="isNotMigrateVirgil(member)">
                    <span>Chat</span>
                    <div class="spinner-border mx-2 loading-btn-icon" role="status">
                      <div class="sr-only">Loading...</div>
                    </div>
                  </button>
                  <button type="button" class="btn bg--babyblue mr-2 border-0 w-md-100"
                    :class="isNotMigrateVirgil(member) ? 'disabled pointer-events-none text-black' : 'txt-pri'"
                    v-if="type === 2 && isOwner && (checkNotMigrated(member) || member.is_not_exist_groupVG)"
                    @click="handleAddMemberToChatChannel(member)" :disabled="isNotMigrateVirgil(member)">
                    {{ $t("Thêm vào chat") }}
                  </button>
                  <div v-if="isAdmin" class="flex flex-col gap-2 md:flex-row" style="white-space: nowrap;">
                    <button type="button" class="btn bg--babyblue txt-pri mr-2 border-0 w-md-100"
                      v-if="type === 2 && !checkIsAdmin(member)" @click="handleAssignAdmin(member, 1)">
                      {{ $t("circle_feature.btn_assign_admin") }}
                    </button>

                    <button type="button" class="btn btn-FEF3F2 w-md-100" v-if="type === 3 && checkNotSeed(member)"
                      @click="handleAssignAdmin(member, 2)">
                      {{ $t("circle_feature.btn_revoke_admin") }}
                    </button>
                    <button type="button" class="btn btn-FEF3F2 w-md-100" v-if="type === 1"
                      @click="rejectMember(member.id)">
                      {{ $t("circle_feature.btn_recall") }}
                    </button>
                    <button type="button" class="btn bg--babyblue txt-pri mr-2 border-0 w-md-100" v-if="type === 0"
                      @click="acceptMember(member.id)">
                      {{ $t("circle_feature.lbl_agree") }}
                    </button>
                    <button type="button" class="btn bg--gray-corl border-0 txt-grey-600 w-md-100" v-if="type === 0"
                      @click="rejectMember(member.id)">
                      {{ $t("circle_feature.lbl_decline") }}
                    </button>
                    <button type="button" class="btn btn-FEF3F2 mr-2 w-md-100" v-if="type === 2 && $user && user.id != $user.id && isOwner
                    " @click="removeMemberOfCircle(member)">
                      {{ $t("circle_feature.btn_delete") }}
                    </button>
                  </div>
                </div>
              </td>
            </tr>
          </tbody>
          <tbody>
            <tr>
              <td colspan="4" class="text-center">
                <a href="javascript:;" class="txt-pri fs-16"
                  v-if="memberPagination.page_num < memberPagination.total_page"
                  @click="getCircleMembers(type, true)">{{ $t("circle_feature.btn_load_more") }}</a>
                <div class="text-center" v-if="(!members || !memberPagination.total) && !member_loading
                ">
                  <p class="fs-16 fw-500">
                    {{ $t("circle_feature.lbl_no_member") }}
                  </p>
                </div>
                <div class="skeleton-line" v-if="member_loading"></div>
              </td>
            </tr>
          </tbody>
        </table>
      </div>
    </div>
    <div class="modal fade" id="modalAddCMem" tabindex="-1" role="dialog" aria-labelledby="modalAddCMemLabel"
      aria-hidden="true">
      <div class="modal-dialog modal-dialog-centered" role="document">
        <div class="modal-content">
          <div class="d-flex justify-content-between p-3">
            <h5 class="modal-title" id="modalUpdateDiseaseLabel">
              {{ $t("circle_feature.btn_add_member") }}
            </h5>
            <button type="button" class="close bg-white border-0" data-dismiss="modal" aria-label="Close">
              <span aria-hidden="true" class="robo-18-500">&times;</span>
            </button>
          </div>
          <div class="moda-body">
            <CircleAddMember @refresh="refresh" :circle="circleData" />
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import CircleAddMember from '@/components/Circles/CircleAddMember'
import ImageBox from '@/components/Common/Image.vue'
import e3kit from '@/libs/e3kit'
import appUtils from '@/utils/appUtils'
import { mixinGoToChat } from '../../../utils/mixinGoToChat'

export default {
  name: 'CircleMembersV2',
  components: { ImageBox, CircleAddMember },
  props: {
    circleData: Object,
    circleMember: Object,
    isAdmin: Boolean,
    isMember: Boolean,
    isOwner: Boolean,
    tabsTotalNumber: Object,
    members: Array,
    memberPagination: Object
  },
  mixins: [mixinGoToChat],
  data () {
    return {
      isLoading: false,
      type: 2,
      member_loading: false
    }
  },
  computed: {
    membersNotAddChatChannel () {
      const idList = this.circleData?.member_not_joined_chat || []
      const memberList = this.allMembers?.filter((item) =>
        idList.findIndex((findItem) => Number(findItem) === item?.user_id)
      )
      return memberList
    },
    membersNotMigrateVirgil () {
      const idList = this.circleData?.member_not_migrate_virgil || []
      const memberList = this.allMembers?.filter((item) =>
        idList.findIndex((findItem) => Number(findItem) === item?.user_id)
      )
      return memberList
    }
  },
  created () { },
  methods: {
    goToProfile (id) {
      if (!id) return
      this.$router.push({ name: 'DoctorProfile', params: { id: id } })
    },
    async getCircleMembers (status, isNext) {
      this.isLoading = true
      this.$emit('onGetMembers', status, isNext, () => {
        this.isLoading = false
      })
    },
    async handleAssignAdmin (member, role) {
      if (!member?.id) return
      const label = this.$t(
        role === 1
          ? 'fm_are_you_sure_to_assign_member_as_administrator'
          : 'fm_are_you_sure_to_remove_member_from_administrator',
        {
          name: member?.user_info?.name || member?.user_info?.email || ''
        }
      )

      this.$confirm(label, {
        dangerouslyUseHTMLString: true,
        cancelButtonText: this.$t('lbl_cancel'),
        confirmButtonText: this.$t('lbl_save')
      })
        .then(async () => {
          try {
            const params = {
              circle_member_id: member.id,
              role
            }
            await this.$rf
              .getRequest('DoctorRequest')
              .putAssignCircleAdmin(params)

            this.$toast.open({
              message: this.$t(
                role === 1
                  ? 'circle_feature.msg_assign_admin_success'
                  : 'circle_feature.msg_revoke_admin_success'
              ),
              type: 'success'
            })
            this.refresh()
          } catch (error) {
            this.$toast.open({
              message: this.$t(
                role === 1
                  ? 'circle_feature.msg_assign_admin_error'
                  : 'circle_feature.msg_revoke_admin_error'
              ),
              type: 'error'
            })
            console.log(error)
          }
        })
        .catch(() => { })
    },
    checkNotSeed (member) {
      return member?.user_id !== this.circleData?.created_by
    },
    getImageURL (a) {
      return appUtils.getImageURL(a)
    },
    showModal (show, modalRef) {
      window.$(modalRef).modal(show ? 'show' : 'hide')
    },
    async rejectMember (id) {
      let r = confirm(this.$t('circle_feature.lbl_confirm_msg'))
      if (!r) return
      let self = this
      await this.$rf
        .getRequest('DoctorRequest')
        .rejectCircleMember(id)
        .then((r) => {
          this.refresh()
          self.$toast.open({
            message: this.$t('workspace_setting.lbl_msg_success'),
            type: 'success'
          })
        })
    },
    async acceptMember (id) {
      let r = confirm(this.$t('circle_feature.lbl_confirm_msg'))
      if (!r) return
      let self = this
      self.isLoading = true
      await this.$rf
        .getRequest('DoctorRequest')
        .acceptCircleMember(id)
        .then(async (r) => {
          this.refresh()

          self.$toast.open({
            message: this.$t('workspace_setting.lbl_msg_success'),
            type: 'success'
          })
          // if (r.status === 200) {
          //   this.$emit('onRefresh', async circleData => {
          //     const hasAddVirgil = await this.handleE2EMember(
          //       r.data,
          //       circleData
          //     )

          //     if (hasAddVirgil) {
          //       this.refresh()
          //       self.$toast.open({
          //         message: this.$t('workspace_setting.lbl_msg_success'),
          //         type: 'success'
          //       })
          //     } else {
          //       this.$toast.open({
          //         message: this.$t(
          //           'Có lỗi xảy ra khi thêm thành viên vào chat channel'
          //         ),
          //         type: 'error'
          //       })
          //     }
          //   })
          // }
        })
        .finally(() => {
          self.isLoading = false
        })
    },
    changeMemType (t) {
      if (t === 0 || t === 1 || t === 2 || t === 3) {
        this.type = t
        this.getCircleMembers(t)
        // this.getTabsTotalNumber()
      }
    },

    refresh () {
      this.getCircleMembers(this.type)
      this.$emit('onRefresh')
      //   this.getTabsTotalNumber()
      this.showModal(false, '#modalAddCMem')
    },
    async messageToDirect (id, e, u) {
      await this.setSelectedMember(u)
      await this.messageTo(id, e)
    },

    checkIsAdmin (member) {
      return member?.status === 2 && member?.role === 1
    },
    checkNotMigrated (member) {
      if (
        !this.circleData?.migrate_channel_gs?.channel_id &&
        member.user_id !== this.circleData?.created_by
      ) {
        return true
      }
      const idList = this.circleData?.member_not_joined_chat || []

      return idList.some((findItem) => {
        return (
          Number(findItem) === member?.user_id &&
          findItem !== String(this.circleData?.created_by)
        )
      })
    },
    isNotMigrateVirgil (member) {
      const idList = this.circleData?.member_not_migrate_virgil || []
      return idList.some((findItem) => {
        return (
          Number(findItem) === member?.user_id &&
          findItem !== String(this.circleData?.created_by)
        )
      })
    },
    async handleAddMemberToChatChannel (member) {
      try {
        this.isLoading = true
        const params = {
          channel_id: this.circleData?.twilio_conversation?.twilio_conv_id,
          user_id: member?.user_id,
          migrate_channel_id: this.circleData?.migrate_channel_gs?.id,
          circle_id: this.circleData?.id
        }

        const response = await this.$rf
          .getRequest('DoctorRequest')
          .addMemberToChannel(params)
        if (response.status === 200) {
          this.$emit('onRefresh', async (circleData) => {
            const hasAddVirgil = await this.handleE2EMember(member, circleData)

            if (hasAddVirgil) {
              this.refresh()
              this.$toast.open({
                message: this.$t('Thêm thành viên vào chat channel thành công'),
                type: 'success'
              })
            } else {
              this.$toast.open({
                message: this.$t(
                  'Có lỗi xảy ra khi thêm thành viên vào chat channel'
                ),
                type: 'error'
              })
            }
            this.isLoading = false
          })
        }
      } catch (error) {
        console.log(error)
        this.$toast.open({
          message: this.$t(
            'Có lỗi xảy ra khi thêm thành viên vào chat channel'
          ),
          type: 'error'
        })
        this.isLoading = false
      }
    },
    async handleE2EMember (member, circleResponse) {
      const circleInfo = circleResponse || this.circleData

      try {
        const userId = String(member?.user_id)
        const ownerId = circleInfo.created_by.toString()
        if (!circleInfo?.migrate_channel_gs?.cid || !userId || !ownerId) {
          return
        }
        const userCard = await e3kit.findUsers(userId)
        const groupId = `group_${circleInfo?.migrate_channel_gs?.cid}`

        const group = await e3kit.getGroupCardData(groupId, ownerId)
        if (group?.participants?.includes(userId)) return true
        if (!group) {
          const participantIds = [`${userId}`]
          await e3kit.deleteGroup(groupId)
          await e3kit.createGroup(groupId, participantIds, ownerId)
        } else {
          await group.add(userCard)
          await group.update()
        }

        return true
      } catch (error) {
        console.log(error)
        return false
      }
    },
    async removeMemberOfCircle (member) {
      let r = confirm(this.$t('circle_feature.lbl_confirm_msg'))
      if (!r) return

      if (!this.circleData?.id || !member?.user_id) return

      try {
        const params = {
          circle_id: this.circleData.id,
          user_id: member?.user_id
        }
        this.isLoading = true
        const response = await this.$rf
          .getRequest('DoctorRequest')
          .removeMemberOfCircle(params)

        if (response?.status !== 200) {
          this.$toast.open({
            message: this.$t('Có lỗi xảy ra khi xoá thành viên'),
            type: 'error'
          })
          return
        }

        await this.handleRemoveVirgilMember(member)

        this.$toast.open({
          message: this.$t('Bạn đã xoá thành viên ra khỏi Circle'),
          type: 'success'
        })
        this.refresh()
      } catch (error) {
        console.log(error)
        this.$toast.open({
          message: this.$t('Có lỗi xảy ra khi xoá thành viên'),
          type: 'error'
        })
      } finally {
        this.isLoading = false
      }
    },
    async handleRemoveVirgilMember (member) {
      try {
        const userId = String(member?.user_id)
        if (
          !this.circleData?.migrate_channel_gs?.cid ||
          !userId ||
          !this.circleData?.created_by
        ) {
          return
        }
        const userCard = await e3kit.findUsers(userId)

        if (!userCard) {
          return
        }

        const groupId = `group_${this.circleData?.migrate_channel_gs?.cid}`

        const group = await e3kit.getGroupCardData(
          groupId,
          this.circleData.created_by.toString()
        )

        if (!group) {
          return
        }

        await group.remove(userCard)
        await group.update()

        return
      } catch (error) {
        console.log(error)
      }
    }
  }
}
</script>

<style lang="scss" scoped>
.custom:hover {
  background-color: #e4e7ec;
}

.px {
  padding: 9px 43px;
}

.ml-40 {
  margin-left: 40px;
}

.container {
  width: 1024px;
}

.p-48 {
  padding: 0px 48px 0px 48px;
}

.white-s-pre {
  white-space: pre-wrap;
}

.icinfo {
  margin-right: 16px;
}

.bd-blur-gray {
  border-color: #98a2b3;
}

.nav-item.active a {
  color: #20419b !important;
  font-weight: 500;
}

.modal-dialog.modal-qr {
  position: relative;
  display: table;
  /* This is important */
  overflow-y: auto;
  overflow-x: auto;
  width: auto;
  min-width: 300px;
}

.btn-FEF3F2 {
  background-color: #fef3f2;
  color: #b42318;
  border-color: #fef3f2;
}

.btn-add-member {
  width: 100%;
  display: flex;
  align-items: center;
  border-radius: 10px;
  border: 1px dashed #20419b;
  font-weight: 400;
  font-size: 14px;
  line-height: 20px;
  /* identical to box height, or 143% */

  /* Primary */

  color: #20419b;
  padding: 8px;
  cursor: pointer;
}

.red-dot-new {
  position: absolute;
  top: 5px;
  right: 10px;
}

.disabled {
  opacity: 0.6;
  cursor: not-allowed;
  pointer-events: none;
}

.loading-btn-icon {
  display: none;
}

.loading-btn .loading-btn-icon {
  display: block !important;
}

.loading-btn {
  pointer-events: none;
  color: white;
  border-color: #98a2b3 !important;
}

.loading-btn span {
  display: none;
}

.disabled.pointer-events-none.text-black {
  color: black !important;
  opacity: 0.3;
}
</style>
