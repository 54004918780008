<template>
  <div v-loading="isLoading" v-if="members && members.length && isOwner" class="bg-white py-2 px-4 mt-4">
    <div class="fs-18 font-bold flex items-center gap-3">
      <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path d="M16.67 13.13C18.04 14.06 19 15.32 19 17V20H23V17C23 14.82 19.43 13.53 16.67 13.13Z" fill="#84818A" />
        <path
          d="M15 12C17.21 12 19 10.21 19 8C19 5.79 17.21 4 15 4C14.53 4 14.09 4.1 13.67 4.24C14.5 5.27 15 6.58 15 8C15 9.42 14.5 10.73 13.67 11.76C14.09 11.9 14.53 12 15 12Z"
          fill="#84818A" />
        <path
          d="M9 12C11.21 12 13 10.21 13 8C13 5.79 11.21 4 9 4C6.79 4 5 5.79 5 8C5 10.21 6.79 12 9 12ZM9 6C10.1 6 11 6.9 11 8C11 9.1 10.1 10 9 10C7.9 10 7 9.1 7 8C7 6.9 7.9 6 9 6Z"
          fill="#84818A" />
        <path
          d="M9 13C6.33 13 1 14.34 1 17V20H17V17C17 14.34 11.67 13 9 13ZM15 18H3V17.01C3.2 16.29 6.3 15 9 15C11.7 15 14.8 16.29 15 17V18Z"
          fill="#84818A" />
      </svg>
      <span>{{ $t("Danh sách thành viên xin vào Chat") }}</span>
    </div>

    <table class="table table-vcenter table-mobile-md card-table no--border table-patient-list">
      <thead>
        <tr>
          <th style="width: 30%">
            <span class="txt-grey-600 fs-14 fw-600 whitespace-nowrap">{{
              $t("circle_feature.lbl_information")
              }}</span>
          </th>
          <th style="width: 10%">
            <span class="txt-grey-600 fs-14 fw-600 whitespace-nowrap">{{
              $t("circle_feature.lbl_action")
              }}</span>
          </th>
        </tr>
      </thead>
      <tbody>
        <tr v-for="member in members" class="cursor-pointer custom" :key="member.id" :set="(user = member.user_info)">
          <td v-if="user" :data-label="$t('circle_feature.lbl_information')" @click="
            goToProfile(user && user.doctor_infor && user.doctor_infor.id)
            ">
            <div class="flex items-center w-full">
              <div class="mr-2">
                <ImageBox v-if="user.avatar" class="avatar avatar-rounded object-cover object-top border" avatar
                  :source="getImageURL(user.avatar)" :alt="user.name || user.email" />
                <span v-if="!user.avatar" class="avatar avatar-rounded mr-0">
                  {{
                    user.name
                      ? user.name.charAt(0)
                      : user.email
                  ? user.email.charAt(0)
                  : "H"
                  }}
                </span>
              </div>
              <div>
                <p class="flex-1 mb-0 fs-14 fw-600">
                  {{ user.name || user.email }}
                </p>
              </div>
            </div>
          </td>

          <td :data-label="$t('circle_feature.lbl_action')">
            <div class="flex flex-col gap-2 md:flex-row w-full" style="white-space: nowrap">
              <button :disabled="isLoading" type="button" class="btn bg--babyblue txt-pri mr-2 border-0 w-md-100"
                @click="handleAddMemberToChatChannel(member, 2)">
                {{ $t("Đồng ý") }}
              </button>
              <button :disabled="isLoading" type="button" class="btn btn-FEF3F2 mr-2 w-md-100"
                @click="handleRejectJoinChat(member)">
                {{ $t("Từ chối") }}
              </button>
            </div>
          </td>
        </tr>
      </tbody>
    </table>
  </div>
</template>

<script>
import ImageBox from '@/components/Common/Image.vue'
import e3kit from '@/libs/e3kit'
import appUtils from '../../../utils/appUtils'

export default {
  name: 'RequestJoinChatV2',
  components: { ImageBox },
  props: {
    members: Array,
    isOwner: Boolean,
    circleData: Object
  },
  data () {
    return {
      isLoading: false
    }
  },
  methods: {
    getImageURL (a) {
      return appUtils.getImageURL(a)
    },
    goToProfile (id) {
      if (!id) return
      this.$router.push({ name: 'DoctorProfile', params: { id } })
    },

    async handleAddMemberToChatChannel (member) {
      let r = confirm(this.$t('circle_feature.lbl_confirm_msg'))
      if (!r) return

      try {
        this.isLoading = true
        const response = await this.handleUpdateStatusJoin(member, 2)
        if (response.status === 200) {
          this.$emit('onRefresh', async (circleData) => {
            console.log(circleData)
            const hasAddVirgil = await this.handleE2EMember(member, circleData)

            if (hasAddVirgil) {
              this.$toast.open({
                message: this.$t('Thêm thành viên vào chat channel thành công'),
                type: 'success'
              })
            } else {
              this.$toast.open({
                message: this.$t(
                  'Có lỗi xảy ra khi thêm thành viên vào chat channel'
                ),
                type: 'error'
              })
            }
            // this.isLoading = false

            // console.log(circleData)
            // await this.handleE2EMember(member, circleData)
            // this.$toast.open({
            //   message: this.$t('Thêm thành viên vào chat channel thành công'),
            //   type: 'success'
            // })
            this.$emit('onGetJoinMembers')
            this.$emit('onGetMembers')
            this.isLoading = false
          })
        }
      } catch (error) {
        console.log(error)
        this.$toast.open({
          message: this.$t(
            'Có lỗi xảy ra khi thêm thành viên vào chat channel'
          ),
          type: 'error'
        })
        this.isLoading = false
      }
    },
    async handleRejectJoinChat (member) {
      let r = confirm(this.$t('circle_feature.lbl_confirm_msg'))
      if (!r) return

      if (!member?.id) return

      const response = await this.handleUpdateStatusJoin(member, 3)
      if (response) {
        this.$emit('onGetJoinMembers')

        this.$toast.open({
          message: this.$t('Thành công'),
          type: 'success'
        })
      } else {
        this.$toast.open({
          message: this.$t('Thất bại'),
          type: 'error'
        })
      }
    },
    async handleUpdateStatusJoin (member, status) {
      if (!this.circleData?.id || !member.user_id || !status) return

      try {
        const params = {
          circle_id: this.circleData.id,
          member_id: member.user_id,
          request_chat: status,
          twilio_conver_id:
            this.circleData?.twilio_conversation?.twilio_conv_id
        }

        const response = await this.$rf
          .getRequest('DoctorRequest')
          .acceptRequestJoinChatCircle(params)

        if (response.status === 200) {
          return response
        }
      } catch (error) {
        console.log(error)
      }
    },
    async handleE2EMember (member, circleResponse) {
      const circleInfo = circleResponse || this.circleData

      try {
        const userId = String(member?.user_id)
        const ownerId = circleInfo.created_by.toString()
        if (!circleInfo?.migrate_channel_gs?.cid || !userId || !ownerId) {
          return
        }
        const userCard = await e3kit.findUsers(userId)
        const groupId = `group_${circleInfo?.migrate_channel_gs?.cid}`

        const group = await e3kit.getGroupCardData(groupId, ownerId)
        if (group?.participants.includes(userId)) return true
        if (!group) {
          const participantIds = [`${userId}`]
          await e3kit.createGroup(groupId, participantIds, ownerId)
        } else {
          await group.add(userCard)
          await group.update()
        }

        return true
      } catch (error) {
        console.log(error)
        return false
      }
    }
  }
}
</script>

<style lang="scss" scoped>
.btn-FEF3F2 {
  background-color: #fef3f2;
  color: #b42318;
  border-color: #fef3f2;
}
</style>
